export default [{
  id: 1,
  name: 'All Users'
}, {
  id: 2,
  name: 'Active Users'
}, {
  id: 3,
  name: 'Prospects'
}, {
  id: 4,
  name: 'Education'
}, {
  id: 5,
  name: 'Entertainment'
}, {
  id: 6,
  name: 'Finance'
}, {
  id: 7,
  name: 'Active Users'
}, {
  id: 8,
  name: 'Government'
}, {
  id: 9,
  name: 'Health Care'
}, {
  id: 10,
  name: 'Hospitality'
}, {
  id: 11,
  name: 'Logics and Transport'
}, {
  id: 11,
  name: 'N.G.O'
}, {
  id: 12,
  name: 'Oil and Gas'
}, {
  id: 13,
  name: 'Personal'
}, {
  id: 14,
  name: 'Real Estate'
}, {
  id: 15,
  name: 'Religion and Faith Based'
}];