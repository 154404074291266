import store from '@/state/store.js';
import $ from 'jquery';
import industries from '@/helpers/adminMessageGroups.js';
export default {
  data() {
    return {
      success: '',
      error: '',
      loading: false,
      formData: {
        type: [],
        message: '',
        perMessage: 0
      },
      rules: {
        message: [{
          required: true,
          message: 'Please Enter your message.',
          trigger: 'change'
        }],
        type: [{
          required: true,
          message: 'Please Select Industry type.',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    industries() {
      return industries;
    },
    findNumberOfRecipient() {
      let {
        message
      } = this.formData;
      if (message.length == 0) {
        return 0;
      }
      if (message.length <= 160) {
        this.formData.perMessage = 1;
        return 1;
      } else {
        if (message.body.length > 160) {
          this.formData.perMessage = Math.ceil(message.body.length / 153);
        }
      }
      // } else if(message.length > 160 &&  message.length < 320 ) {
      //      this.formData.perMessage = 2
      //     return 2
      // } else if( message.length > 320 && message.length < 460 ) {
      //      this.formData.perMessage = 3
      //     return 3
      // }
    }
  },
  methods: {
    hideModal() {
      $('#sendmessagetoclient').modal('hide');
      this.error = '';
      this.success = '';
      this.loading = false;
      this.formData = {
        type: [],
        message: ''
      };
    },
    submitAction(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true;
          this.success = '';
          delete this.formData.perMessage;
          return store.dispatch('admin/massMessage/messageClients', this.formData).then(message => {
            this.loading = false;
            this.success = message;
          }).catch(err => {
            var _err$response;
            this.loading = false;
            if ((err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data) == undefined) {
              this.errors = "Network error try again.";
            }
          });
        } else {
          this.loading = false;
          return false;
        }
      });
    }
  },
  mounted() {
    this.error = '';
    this.success = '';
    this.loading = false;
    this.formData = {
      type: [],
      message: ''
    };
    //   store.dispatch('admin/massMessage/getIndustries').then( data => console.info(data))
  }
};