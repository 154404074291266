import $ from 'jquery';
import SendMessageToClients from './SendMessageToClients.vue';
import MessageDebtors from './MessageDebtors';
import appConfig from '@/app.config';
export default {
  page: {
    title: 'Mass messages',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  name: 'users',
  components: {
    SendMessageToClients,
    MessageDebtors
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    messageClients() {
      let element = this.$refs.sendmessagetoclient.$el;
      $(element).modal('show');
    },
    messageDebtors() {
      let element = this.$refs.messageDebtors.$el;
      $(element).modal('show');
    }
  },
  mounted() {}
};