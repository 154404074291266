var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "__main_card_container"
  }, [_c('div', {
    staticClass: "_main_card_header"
  }, [_c('div', {
    staticClass: "__header_inner admin_header_padding"
  }, [_c('h2'), _c('el-dropdown', {
    attrs: {
      "trigger": "click"
    }
  }, [_c('el-button', {
    attrs: {
      "id": "create_new_account"
    }
  }, [_vm._v(" Create new message ")]), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('el-dropdown-item', [_c('button', {
    staticClass: "__dropdown_btn",
    on: {
      "click": function ($event) {
        return _vm.messageClients();
      }
    }
  }, [_vm._v("Message Clients")])]), _c('el-dropdown-item', [_c('button', {
    staticClass: "__dropdown_btn",
    on: {
      "click": function ($event) {
        return _vm.messageDebtors();
      }
    }
  }, [_vm._v("Message Debtors")])])], 1)], 1)], 1)]), _c('div', {
    staticClass: "main_card_body"
  }), _c('SendMessageToClients', {
    ref: "sendmessagetoclient"
  }), _c('MessageDebtors', {
    ref: "messageDebtors"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };